import * as React from 'react';
import clsx from 'clsx';

import Layout from 'parts/Layout';
import HeroSection from 'parts/HeroSection';
import TheKintsugiCrowdloanSection from 'parts/TheKintsugiCrowdloanSection';
import TokenomicsAndTheTokenModelSection from 'parts/TokenomicsAndTheTokenModelSection';
import BackedBySection from 'parts/BackedBySection';
import HowItWorksSection from 'parts/HowItWorksSection';
import AuditedAndPeerReviewedSection from 'parts/AuditedAndPeerReviewedSection';

const App = (): JSX.Element => {
  return (
    <>
      <Layout>
        <div
          className={clsx(
            'py-6',
            'md:py-28',
            'space-y-20',
            'max-w-7xl',
            'mx-auto'
          )}>
          <HeroSection />
          <HowItWorksSection />
          <TheKintsugiCrowdloanSection />
          <TokenomicsAndTheTokenModelSection />
          <BackedBySection />
          <AuditedAndPeerReviewedSection />
        </div>
      </Layout>
    </>
  );
};

export default App;


import clsx from 'clsx';

const HeroSection = (): JSX.Element => (
  <section>
    <div className='space-y-10'>
      <div className='space-y-10'>
        <h1 className={clsx('text-center', 'text-4xl', 'font-abcWhyte')}>
          Kintsugi
        </h1>
        <p
          className={clsx(
            'text-center',
            'text-xl',
            'font-bold'
          )}>
          The claims period for the Kintsugi Crowdloan has ended.
        </p>
        <p
          className={clsx(
            'text-center',
            'text-xl',
            'font-bold',
            'text-kintsugiOchre'
          )}>
          Thanks for Voting for trustless Bitcoin on Kusama
        </p>
      </div>
    </div>
  </section>
);

export default HeroSection;
